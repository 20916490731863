import React from 'react';
import logo from './img/2ndlogo.png';
import 'react-phone-number-input/style.css';

function Otp(){
  return (
  <>
      <div className='container-fluid my-4 text-center' style={{}}>
    <img src={logo} alt="logo" style={{height:'auto',width:'70%'}} />
    </div>

    <div className='ms-5'>
        <p style={{fontFamily:"roboto"}}>Enter Your</p>
        <h1 style={{fontFamily:"roboto"}}>Contact Number</h1>

          <div className="form-group text-center mt-5 d-flex" style={{width:"70%"}}>
            <span className="border-end country-code mt-1">+91</span>
            <input type="number" className="form-control" style={{}} id="ec-mobile-number" aria-describedby="emailHelp" placeholder="&nbsp;&nbsp;&nbsp;1 2 3 4&nbsp;&nbsp;&nbsp;&nbsp;4 5 6&nbsp;&nbsp;&nbsp;&nbsp;7 8 9" />
          </div>
    </div>
  </>
  )
}

export default Otp;