import React from 'react';
import '../src/OrderSummary.css';
import {NavLink} from 'react-router-dom';

const OrderSummary = () => {
  return (
    <>
    <div className='container'>
        <div className='row'>
            <div className='col-12'>
                <p className='OrderSummaryH1 mt-2 text-center'>Order Preview</p>
                <p className='OrderSummaryP1 mt-1 text-center'>Order Summary</p>

                <div class="card" style={{width:"100%"}}>
                  <div class="card-body">
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <p className='OrderSummaryP2'>Category</p>
                        <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600",fontStyle:"roboto"}}>Electronics</p>
                      </div>
                      <p className='OrderSummaryP3'>Pending</p>
                    </div>
                    <div className='mt-3  '>
                      <p className='OrderSummaryP4'>Sub-Category</p>
                      <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600",fontStyle:"roboto"}}>Television</p>
                    </div>
                    <div className='mt-4'>
                      <p className='OrderSummaryP5'>Weight</p>
                      <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600",fontStyle:"roboto"}}>24 Kilograms</p>
                    </div>
                    <p className='mt-5 OrderSummaryP6'>Pick Up Location</p>
                    <p className='OrderSummaryH2'>Plot Number 116, Lane Number 4, Rathore Nagar, Vaishali Nagar</p>
                    <p style={{fontSize:"14px",fontStyle:"roboto"}}>PIN <span style={{fontWeight:"600"}}>302021</span></p>
                    <div className='d-flex' style={{lineHeight:"5px"}}>
                      <p style={{fontSize:"14px",fontStyle:"roboto"}}>District Jaipur</p>
                      <p className='ms-5' style={{fontSize:"14px",fontStyle:"roboto"}} >State Rajasthan</p>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column mt-5'>
                  <NavLink to='/SellScrap'>
                    <button className='OrderSummarybtn1'>Continue Selling Scrap</button>
                  </NavLink>
                  <NavLink to='/SelectLocation'>
                    <button className='OrderSummarybtn2 mt-3'>Place Order</button>
                  </NavLink>
                </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default OrderSummary;