import React from 'react'
import logo from './img/2ndlogo.png'
import { NavLink } from 'react-router-dom';

function Name() {
  return (
   <>

<div className='container-fluid my-2 text-center' style={{}}>
    <img src={logo} alt="logo" style={{height:'70%',width:'70%'}} />
</div>


  <div className='ms-5' style={{marginTop:'100px'}}>
    <h3 style={{fontWeight:700,fontFamily:"roboto"}}>Last Step</h3>
    <p style={{fontFamily:"roboto"}}>Enter Your Name</p>
  </div>  

    <div className="ms-5" style={{width:"75%",height:'7vh'}}>
      <input style={{width:"100%",height:'7vh',borderRadius:'10px`',boxShadow:'none',outline:'none',border:'1px solid #D9D9D9',fontFamily:"roboto"}} type="text" className="" placeholder="" aria-label="Username" aria-describedby="basic-addon1"/>
    </div>
  

    <NavLink to='/Home'>
      <div className='text-center'>
      <button style={{width:'80%',height:"7vh",backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:'400',marginTop:"20%",fontFamily:"roboto",}}>Start Selling Scrap</button>
      </div>
    </NavLink>
   </>
  )
}

export default Name;