import React from 'react';
// import { BsList } from "react-icons/bs";
import '../src/Header.css';
import HomeDataImgHeader from './img/HomeDataImgHeader.png';
import { RiNotification2Fill } from "react-icons/ri";
import log from '../src/img/scrap.png';

const Header = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='d-flex'>
          <div className='col-2'>
            <div className='mt-2 mx-2 HeaderImgScrap1' style={{width:"50px",height: "50px",borderRadius: "50%",border:"2px solid #EFEFEF",}}>
            <img className='HeaderImgScrap11 mt-3 mx-3' style={{width:"20px",height:"auto"}}src={HomeDataImgHeader} alt=''/>
            </div>
            {/* <BsList style={{marginTop:"18px",marginLeft:"10px",fontSize:"25px",color:"#868180"}}/> */}
          </div>
          <div className='col-8'>
              <img className='HeaderImgScrap2'  src={log} alt="Logo"/>
          </div>
          <div className='col-2'>
            <div className='text-center'>
            <RiNotification2Fill style={{marginTop:"15px",fontSize:"25px",color:"#868180"}}/>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;