// import Header from "./Header";
import React from "react";
import {Routes, Route } from "react-router-dom";
// import HomeData from "./HomeData";
import Firstpage from "./Firstpage";
import Secondpage from "./Secondpage";
import Thirdpage from "./Thirdpage";
import Number from "./Number";
import Checkotp from "./Checkotp";
import Verification from "./Verification";
import Startselling from "./Startselling";
import Name from "./Name";
import Home from "./Home";
import OrderPage from "./OrderPage";
import OrderAll from "./OrderAll";
import OrderPending from "./OrderPending";
import OrderComplete from "./OrderComplete";
import OrderDetail from "./OrderDetail";
import OrderDetail1 from "./OrderDetail1";
import Feedback from "./Feedback";
import Support from "./Support";
import Profile from "./Profile";
import ProfileEdit from "./ProfileEdit";
import SellScrap from "./SellScrap";
import SellScrap2 from "./SellScrap2";
import SellScrap3 from "./SellScrap3";
import OrderSummary from "./OrderSummary";
import SelectLocation from "./SelectLocation";
import UploadPhoto from "./UploadPhoto";
import PhotoChange from "./PhotoChange";
import OrderSuccessfully from "./OrderSuccessfully";
import HoldTight from "./HoldTight";
import OrderStatus from "./OrderStatus";






function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Firstpage/>}/>
        <Route path="/Firstpage" element={<Firstpage/>}/>
        <Route path="/Secondpage" element={<Secondpage/>}/>
        <Route path="/Thirdpage" element={<Thirdpage/>}/>
        <Route path="/Number" element={<Number/>}/>
        <Route path="/Checkotp" element={<Checkotp/>}/>
        <Route path="/Verification" element={<Verification/>}/>
        <Route path="/Startselling" element={<Startselling/>}/>
        <Route path="/Name" element={<Name/>}/>
        <Route path="/Home" element={<Home/>}/>
        <Route path="/OrderPage" element={<OrderPage/>}/>
        <Route path="/OrderAll" element={<OrderAll/>}/>
        <Route path="/OrderPending" element={<OrderPending/>}/>
        <Route path="/OrderComplete" element={<OrderComplete/>}/>
        <Route path="/OrderDetail" element={<OrderDetail/>}/>
        <Route path="/OrderDetail1" element={<OrderDetail1/>}/>
        <Route path="/Feedback" element={<Feedback/>}/>
        <Route path="/Support" element={<Support/>}/>
        <Route path="/Profile" element={<Profile/>}/>
        <Route path="/ProfileEdit" element={<ProfileEdit/>}/>
        <Route path="/SellScrap" element={<SellScrap/>}/>
        <Route path="/SellScrap2" element={<SellScrap2/>}/>
        <Route path="/SellScrap3" element={<SellScrap3/>}/>
        <Route path="/OrderSummary" element={<OrderSummary/>}/>
        <Route path="/SelectLocation" element={<SelectLocation/>}/>
        <Route path="/UploadPhoto" element={<UploadPhoto/>}/>
        <Route path="/PhotoChange" element={<PhotoChange/>}/>
        <Route path="/OrderSuccessfully" element={<OrderSuccessfully/>}/>
        <Route path="/HoldTight" element={<HoldTight/>}/>
        <Route path="/OrderStatus" element={<OrderStatus/>}/>
      </Routes>
    </>
  );
}

export default App;
