import React from 'react';
import '../src/OrderPending.css';
// import { FaChevronLeft } from "react-icons/fa";
// import {useNavigate} from 'react-router-dom';

const OrderPending = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 col-md-6 col-sm-12'>
                    <div style={{height:"80vh",overflowY:"scroll"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    <div class="mt-3 card" style={{width:"100%",marginBottom:"20%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderPendingP2'>14 kg</p>
                                <p className='OrderPendingP3'>Pending</p>
                                
                            </div>
                            <p className='OrderPendingP4'>Electronics</p>
                                <p className='OrderPendingMar03'>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default OrderPending;