import React from 'react';
import '../src/Feedback.css';
import FeedbackImg from './img/FeedbackImg.gif';
import { RiHome6Line } from "react-icons/ri";
import {NavLink} from 'react-router-dom';

const Feedback = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 col-md-6 col-sm-6'>
                    <p className='FeedbackP1 mt-1'>Thanks for Your Feedback !!</p>
                    <img className='FeedbackImg1' src={FeedbackImg} alt='jk'/>
                    <p className='FeedbackP2'>My Reason For Cancellation is </p>
                    <p className='FeedbackP3'>Longer Pick up Time</p>
                    <NavLink to='/Home'>
                    <button className='FeedbackBtn1 mt-5'><RiHome6Line style={{fontSize:"30px"}}/>&nbsp;&nbsp;&nbsp;Home</button>
                    </NavLink>
                    <p className='FeedbackP4 mt-3'>We will make sure that you don’t Have to <br/> face same problem in future </p>
                </div>
            </div>
        </div>
    </>
  );
}

export default Feedback;