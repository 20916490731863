import React from 'react';
import logo from './img/3rdlogo.png'
import verifi from './img/premium.png'
import { useNavigate } from 'react-router-dom';

function Verification(props) {
   const Navigate = useNavigate();
   if (props.Santa===6) {
    setTimeout(()=>{
      Navigate("/Startselling");
    },4000)
   }
  return (
   <>
      <div className='container-fluid mt-5 text-center' style={{}}>
        <img src={logo} alt="logo" style={{height:'50%',width:'50%'}} />
      </div>

      <div className='d-flex text-center' style={{justifyContent:'center'}}>
        <div style={{border:'1px solid #D9D9D9',width:'60%',padding:'7px',borderRadius:'10px',fontSize:'16px'}}>Mobile Number Verified <span className='ms-1 '><img src={verifi} alt="logo" style={{height:'20px',width:'20px',justifyContent:'center'}} /></span></div>
      </div>

      <div className='text-center mt-5'>
          <h1 style={{fontWeight:700,fontSize:48,fontFamily:"roboto"}}>902 496</h1>
      </div>
   

      <div className='text-center mt-3'>
       <p style={{fontSize:'14px',fontFamily:"roboto"}}>will be Used as primary Contact <br/> Number. You Can Change This Later</p>
      </div>
   </>
  )
}

export default Verification;
