import React from 'react';
import '../src/Startselling.css';
import logo from './img/2ndlogo.png';
import verifi from './img/premium.png';
import { NavLink } from 'react-router-dom';

function Startselling() {

  return (
    <>

<div className='container-fluid my-1   text-center' style={{}}>
    <img src={logo} alt="logo" style={{height:'70%',width:'70%'}} />
</div>

<div className='d-flex text-center' style={{justifyContent:'center',marginTop:'50px'}}>
   <div style={{border:'1px solid #D9D9D9',width:'60%',padding:'7px',borderRadius:'10px',fontSize:'16px',fontFamily:"roboto"}}>Mobile Number Verified <span className='ms-1 '><img src={verifi} alt="logo" style={{height:'20px',width:'20px',justifyContent:'center'}} /></span></div>
</div>

<div className='text-center mt-4'>
    <h1 style={{fontWeight:700,fontSize:48,fontFamily:"roboto"}}>902 496 7391</h1>
</div>

<div className='text-center mt-3'>
    <p style={{fontSize:'14px',fontFamily:"roboto"}}>will be Used as primary Contact <br/> Number. You Can Change This Later</p>
</div>

<div className='text-center' style={{marginTop:60}}>
<NavLink to='/Name'>
<button style={{padding:'12px',width:'80%',backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:'400',fontFamily:"roboto"}}>Start Selling Scrap</button>
</NavLink>
</div>

      <div className='row fixed-bottom'>
                <div className='col-3'>
                <div class="box1"></div>
                </div>
                <div className='col-6'>
                <div class="box2"></div>
                </div>
                <div className='col-3'>
                <div class="box3"></div>
                </div>
            </div>

    </>
  )
}

export default Startselling;