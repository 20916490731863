import React, { useState } from 'react';
import { MdLocationOn } from "react-icons/md";
import '../src/SelectLocation.css';
import {NavLink} from 'react-router-dom';
import { AiOutlineMinus } from "react-icons/ai";
import { MdAddLocationAlt } from "react-icons/md";


const SelectLocation = () => {
  const [selected,setSelected] = useState(false);
  return (
    <>
        <div className='container' style={{marginTop:"35%"}}>
            <div className='row'>
                <div className='col-12'>
                    <p className='SelectLocationp1' style={{fontSize:"24px",fontWeight:"600",color:"#4B4B4B",lineHeight:"1px"}}>Select Location&nbsp;<MdLocationOn style={{color:"orangered",marginTop:"-5px",fontSize:"25px"}}/></p>
                    <p style={{lineHeight:"1px",fontFamily:"roboto"}}>My Locations</p>
                    <div className="card mt-4" onClick={()=>setSelected(true)} style={{width:"100%",border:selected?"1px solid orangered":"1px solid slaty"}}>
                        <div className="card-body">
                          <h5 style={{color:"#4B4B4B",fontFamily:"roboto"}}>Plot Number 116, Lane Number 4, Rathore Nagar, Vaishali Nagar</h5>
                          <p style={{fontSize:"14px",color:"#4B4B4B",fontFamily:"roboto"}}>PIN <span style={{fontWeight:"600"}}>302021</span></p>
                          <div className='d-flex'>
                            <p style={{fontSize:"14px",color:"#4B4B4B",fontFamily:"roboto"}}>District <span style={{fontWeight:"600"}}> Jaipur</span></p>
                            <p className='mx-4' style={{fontSize:"14px",color:"#4B4B4B",fontFamily:"roboto"}}>State <span style={{fontWeight:"600"}}> Rajasthan</span></p>
                          </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mt-4'>
                        <NavLink to='/UploadPhoto'>
                        <button disabled={!selected} style={{
                          backgroundColor:selected?"#EA5932":"salty",color:selected?"#FFFFFF":'#677079'
                        }} className='SelectLocationBtn1'>Confirm Location</button>
                        </NavLink>
                        <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                         className='SelectLocationBtn2 mt-3'>+Add Location</button>
                    </div>
                          {/* offcanvas */}
                      <div style={{height:"65vh",borderRadius:"25px 25px 0px 0px"}} className="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                          <div className='text-center m-0' type="button" data-bs-dismiss="offcanvas" aria-label="Close"><AiOutlineMinus style={{fontSize:"30px"}}/></div>
                        <div className="offcanvas-body small">
                          <div className='d-flex'>
                          <p className='SelectLocationP1'>Add Location</p>
                          <MdAddLocationAlt className='SelectLocationIcon1 mt-1 mx-2'/>
                          </div>
                        <input class="SelectLocationinput1" type="text" placeholder="Address..." name="Address" required />
                        <p className='SelectLocationP2 float-end'>0/20</p>
                        <div className='d-flex'>
                        <input class="SelectLocationinput2 mt-4" type="text" placeholder="Pincode" name="Pincode" required />
                        <input class="SelectLocationinput3 mt-4 mx-3" type="text" placeholder="District" name="District" required />
                        </div>
                        <input class="SelectLocationinput4 mt-3" type="text" placeholder="State" name="State" required />
                        <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className='SelectLocationBtn22 mt-5'>+Add Location</button>
                        </div>
                      </div>
                      {/* offcanvas */}
                      {/* offcanvas */}

                      {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Toggle right offcanvas</button> */}

                        <div style={{height:"65vh",borderRadius:"25px 25px 0px 0px",marginTop:"70%"}} class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                            <div className='text-center' type="button"data-bs-dismiss="offcanvas" aria-label="Close"><AiOutlineMinus style={{fontSize:"30px"}}/></div>
                          <div class="offcanvas-body">
                            <p className='SelectLocationP3'>Location Added<br/> Successfully</p>
                            <p className='SelectLocationP4'>Address</p>
                            <p className='SelectLocationP5'>Plot Number 116, Lane Number 4, Rathore Nagar, Vaishali Nagar</p>
                            <p className='SelectLocationP6'>PIN <span style={{fontWeight:"500"}}>302021</span></p>
                            <div className='d-flex'>
                              <p className='SelectLocationP7'>District<span style={{fontWeight:"500"}}> Jaipur</span></p>
                              <p className='SelectLocationP8 mx-5'>State <span style={{fontWeight:"500"}}>Rajasthan</span></p>
                            </div>
                          </div>
                        </div>

                      {/* offcanvas */}

                </div>
            </div>
        </div>
    </>
  );
}

export default SelectLocation;