import React from 'react';
import "../src/SellScrap2.css";
import {NavLink} from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import {useNavigate} from 'react-router-dom'

const SellScrap2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
           <div onClick={()=>navigate(-1)}>
            <FaChevronLeft className='SellScrap2Icon1 mt-2 mx-2'/>
           </div>
            <p className='SellScrap2H1 mt-4 mx-2'>Sell Your Scrap</p>
            <p className='SellScrap2p1 mx-2'>In Just <span style={{color:"#ffa500 "}}>3</span> Clicks&nbsp;&nbsp;<BsArrowRight/></p>
            <p className='mt-4 mx-2' style={{color:"#ffa500",fontSize:"20px",fontWeight:"600",fontStyle:"roboto"}}>Click 2</p>
            <div class="card">
              <div class="card-body">
                <h3 style={{fontStyle:"roboto"}}>Scrap Sub-Category</h3>
                <div style={{height:'55vh', overflowY:'scroll'}}>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Television <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                  <NavLink style={{textDecoration:"none"}} to="/SellScrap3">
                  <button className='SellScrap2Electronicsbtn mt-2 d-flex justify-content-between'>Smartphone <span><span style={{color:"orange"}}>34</span>/Kg</span></button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellScrap2;