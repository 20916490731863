import React from 'react';
import "../src/Support.css"
import { FaChevronLeft } from "react-icons/fa";
import {useNavigate} from 'react-router-dom';
import Lottie from 'react-lottie-player';    
import data from './img/Support-blueberry-cartoon.json';
import SupportAgent from './img/support-agent.png';
import SupportArrow from './img/Support-arrow.png';
import SupportEmail from './img/Support-email.png';
import SupportVector from './img/Support-Vector.png';


const Support = () => {
    const navigate = useNavigate();
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 col-md-6 col-sm-6'>
                    <div onClick={()=>navigate(-1)}>
                        <FaChevronLeft className='SupportIcon1 mt-2 mx-2'/>
                    </div>
                    <p className='SupportP1 mt-3'>Got, some issues ?</p>
                    <p className='SupportP2 mt-3'>We are here<br/> to Help</p>
                        <div className='SupportLottie'>
                            <Lottie
                                loop
                                animationData={data}
                                play
                                style={{ width:"70%",height:"auto"}}
                            />
                        </div>
                        <div className="card mb-3" style={{width:"100%",border:"2px solid black",borderRadius:"10px"}}>
                            <div className="row">
                              <div className="col-lg-2 col-2">
                                <img src={SupportAgent} className='mt-4 mx-3' alt="..."/>
                              </div>
                              <div className="col-lg-8 col-8">
                                    <p className='SupportP3 mt-1'>Talk to our Representative</p>
                                    <p className='SupportP4'>Available from Mon-Sat<br/> ( 10:00 am - 6:00 pm )</p>
                              </div>
                              <div className="col-lg-2 col-2">
                                <img src={SupportArrow} className='mt-4' alt="..." />
                              </div>
                            </div>
                        </div>
                        <div className="card mb-3" style={{width:"100%",border:"2px solid black",borderRadius:"10px"}}>
                            <div className="row">
                              <div className="col-lg-2 col-2">
                                <img src={SupportEmail} className='mt-3 mx-3' alt="..."/>
                              </div>
                              <div className="col-lg-8 col-8">
                                    <p className='SupportP5 mt-1'>Get Email Support</p>
                                    <p className='SupportP6'>Available 24*7</p>
                              </div>
                              <div className="col-lg-2 col-2">
                                <img src={SupportArrow} className='mt-3' alt="..." />
                              </div>
                            </div>
                        </div>
                        <div className="card mb-3" style={{width:"100%",border:"2px solid black",borderRadius:"10px",backgroundColor:"#fff1f3"}}>
                            <div className="row">
                              <div className="col-lg-2 col-2">
                                <img src={SupportVector} style={{width:"28px"}} className='mt-3 mx-3' alt="..."/>
                              </div>
                              <div className="col-lg-8 col-8">
                                    <p className='SupportP7 mt-1'>Get Chat Support</p>
                                    <p className='SupportP8'>Coming Soon</p>
                              </div>
                              <div className="col-lg-2 col-2">

                              </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Support;