import React from 'react';
import '../src/OrderAll.css';
import { NavLink } from 'react-router-dom';
// import { FaChevronLeft } from "react-icons/fa";
// import {useNavigate} from 'react-router-dom';

const OrderAll = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 col-md-6 col-sm-12'>
                    <div style={{height:"80vh",overflowY:"scroll"}}>
                    <NavLink to='/OrderDetail' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>14 kg</p>
                                <p className='OrderAllP3'>Pending</p>
                            </div>
                            <p className='OrderAllP4'>Electronics</p>
                            <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail1' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>40 kg</p>
                                <p className='OrderAllP33'>Completed</p>
                            </div>
                            <p className='OrderAllP4'>Paper</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>14 kg</p>
                                <p className='OrderAllP3'>Pending</p>
                            </div>
                            <p className='OrderAllP4'>Electronics</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail1' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>40 kg</p>
                                <p className='OrderAllP33'>Completed</p>
                            </div>
                            <p className='OrderAllP4'>Paper</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>14 kg</p>
                                <p className='OrderAllP3'>Pending</p>
                            </div>
                            <p className='OrderAllP4'>Electronics</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail1' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>40 kg</p>
                                <p className='OrderAllP33'>Completed</p>
                            </div>
                            <p className='OrderAllP4'>Paper</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>14 kg</p>
                                <p className='OrderAllP3'>Pending</p>
                            </div>
                            <p className='OrderAllP4'>Electronics</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail1' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>40 kg</p>
                                <p className='OrderAllP33'>Completed</p>
                            </div>
                            <p className='OrderAllP4'>Paper</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>14 kg</p>
                                <p className='OrderAllP3'>Pending</p>
                            </div>
                            <p className='OrderAllP4'>Electronics</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to='/OrderDetail1' style={{textDecoration:"none"}}>
                    <div class="mt-3 card" style={{width:"100%",marginBottom:"20%"}}>
                        <div class="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='OrderAllP2'>40 kg</p>
                                <p className='OrderAllP33'>Completed</p>
                            </div>
                            <p className='OrderAllP4'>Paper</p>
                                <p style={{color:"#9B9B9B",fontSize:"16px",fontWeight:"390"}}>Order Created : 03 Mar 2023</p>
                        </div>
                    </div>
                    </NavLink>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default OrderAll;