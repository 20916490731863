import React from 'react';
import '../src/HoldTight.css';
import { FaTruckPickup } from "react-icons/fa";
import { IoMdAlarm } from "react-icons/io";



const HoldTight = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-6 col-sm-12'>
            <p className='HoldTightP1 text-center mt-5'>Hold Tight!!</p>
            <p className='HoldTightP2 text-center'>We are Processing Your Order. Do not<br/> Refresh of Quit Application</p>
            <div className='card'>
              <p className='HoldTightP3 text-center mt-3'>Did You Know ?</p>
              <p className='HoldTightP4 text-center'>An average Indian Generates Over 540 Kg <br/>of Bio Waste In One Year !!</p>
            </div>
            <p className='HoldTightP5 mt-4'>Order Status</p>
            <div className='d-flex'>
              <FaTruckPickup className='HoldTightIcon1 mt-3'/>&emsp;&emsp;
              <p className='HoldTightP6 mt-3'>Locating Nearby Kabaadiwala</p>
            </div>
            <div className='d-flex'>
              <IoMdAlarm className='HoldTightIcon2 mt-5'/>&emsp;&emsp;
              <p className='HoldTightP7'>Scheduling Pick Up</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HoldTight;