import React from 'react';
import logo from './img/scrap.png';
import third from './img/3rd.png';
import black from './img/black.png';
import White from './img/white.png';

function Thirdpage() {
  return (
    <>


<div className='container-fluid my-5 text-center' style={{}}>
    <img src={logo} alt="logo" style={{height:'70%',width:'70%'}} />
    </div>

    <div className='container-fluid text-center p-4' style={{}}>
    <img  src={third} alt="logo" style={{height:'36vh',width:'80%'}} />
    </div>
     

     <div className='text-center my-1' style={{display:'flex',justifyContent:'center'}}>

     <div className='ms-2' style={{}}>
    <img src={White} alt="logo" style={{height:'10px',width:'10px'}} />
    </div>

    <div className='ms-2' style={{}}>
    <img src={White} alt="logo" style={{height:'10px',width:'10px'}} />
    </div>

    <div className='ms-2' style={{}}>
    <img src={black} alt="logo" style={{height:'10px',width:'10px'}} />
    </div>

     </div>

     <div className='text-center my-4'>
        <p style={{fontSize:'25px',fontWeight:500,fontFamily:"roboto"}}>100% Secured & Tested <br/> Weighing Scales</p>
     </div>
    </>
  )
}

export default Thirdpage;