import React from 'react';
import '../src/OrderStatus.css';
import { BsCheck2 } from "react-icons/bs";
import { RiHome6Line } from "react-icons/ri";
import {NavLink} from 'react-router-dom';


const OrderStatus = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 col-md-6 col-sm-6'>
                    <p className='OrderStatusP1 mt-2'>Order Status</p>
                    <div className='d-flex mt-5'>
                        <BsCheck2 className='OrderStatusIcon1'/>&emsp;&emsp;
                        <p className='HoldTightP6 mt-3'>Kabaadiwala Located</p>
                    </div>
                    <div className='d-flex'>
                        <BsCheck2 className='OrderStatusIcon2 mt-5'/>&emsp;&emsp;
                        <p className='OrderStatusP7 mt-5'>Pick Up Scheduled</p>
                    </div>
                    <div className='card mt-5'>
                        <p className='OrderStatusP8 text-center mt-3'>We Will Pick Up Your Scrap From your<br/> Selected Location within Next 24 Hours</p>
                    </div>
                    <NavLink to='/Home'>
                        <button className='OrderStatusBtn1'><span style={{fontSize:"30px"}}><RiHome6Line/></span>&emsp;Home</button>
                    </NavLink>
                </div>
            </div>
        </div>
    </>
  );
}

export default OrderStatus;