import React from 'react';
import "../src/SellScrap3.css"
import { FaChevronLeft } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import {NavLink, useNavigate} from 'react-router-dom'

const SellScrap3 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
           <div onClick={()=>navigate(-1)}>
            <FaChevronLeft className='SellScrap3Icon1 mt-2 mx-2'/>
           </div>
            <p className='SellScrap3p1 mt-4 mx-2'>Sell Your Scrap</p>
            <p className='SellScrap3p2 mx-2'>In Just <span style={{color:"#ffa500 "}}>3</span> Clicks&nbsp;&nbsp;<BsArrowRight/></p>
            <p className='mt-4 mx-2' style={{color:"#ffa500",fontSize:"20px",fontWeight:"600"}}>Click 3</p>
            <h3 className='SellScrap3h3 mt-2 mx-2'>Enter Approx<br/> Weight of Scrap</h3>
            <div className='row'>
                <div className='col-6'>
                  <div className='mt-3 mx-2'>
                    <input className='SellScrap3Input1' type='text' placeholder='Enter Weight...'></input>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='mt-3 mx-5'>
                    <button className='SellScrap3Btn1' >Kilograms</button>
                  </div>
                </div>
            </div>
            <p className='SellScrap3P3 mt-4 mx-2'>Weight need not to be Accurate, We<br/> Just need an Idea.</p>
            <NavLink to='/OrderSummary'style={{textDecoration:"none"}}>
              <button className='SellScrap3btn2 mt-5'>Sell My Scrap</button>
            </NavLink>
            <p className='SellScrap3P4'>Note:By Proceeding You Accept our <span style={{color:"#EA5932"}}>Privacy Policy</span> and<br/> <span style={{color:"#EA5932"}}>Terms & Conditions</span></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellScrap3;