import React, { useState } from 'react'
import logo from './img/scrap.png';
import first from './img/1st.png';
import black from './img/black.png';
import White from './img/white.png';
import {HiOutlineArrowNarrowRight} from 'react-icons/hi';
import Secondpage from './Secondpage';
import Thirdpage from './Thirdpage';
import Otp from './Number';
import Checkotp from './Checkotp';
import Verification from './Verification';
import Startselling from './Startselling';
import Name from './Name';
// import Home from './Home';
// import { NavLink } from 'react-router-dom';
import 'animate.css';

const Firstpage = () => {
    const [value,setValue] = useState(1);
  return (
    <>
    <div style={{display:value===1?'block':'none'}} >

    <div className='container-fluid my-5 text-center' style={{}}>
    <img src={logo} alt="logo" style={{height:'70%',width:'70%'}} />
    </div>

    <div className='container-fluid text-center p-4' style={{}}>
    <img src={first} alt="logo" style={{height:'36vh',width:'90%'}} />
    </div>
     

     <div className='text-center my-1' style={{display:'flex',justifyContent:'center'}}>

     <div className='ms-2' style={{}}>
    <img src={black} alt="logo" style={{height:'10px',width:'10px'}} />
    </div>

    <div className='ms-2' style={{}}>
    <img src={White} alt="logo" style={{height:'10px',width:'10px'}} />
    </div>

    <div className='ms-2' style={{}}>
    <img src={White} alt="logo" style={{height:'10px',width:'10px'}} />
    </div>

     </div>

     <div className='text-center my-4'>

        <p style={{fontSize:'25px',fontWeight:500,fontFamily:"roboto"}}>Buying Your Scrap at <br/> Your Doorstep</p>
     </div>
    
    <div className='text-center'>
    <button onClick={()=>{setValue(value+1)}} style={{padding:'12px',width:'80%',backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:400,fontFamily:"roboto"}}>Get Started<span></span><HiOutlineArrowNarrowRight className='ms-2' style={{fontSize:'20px'}}/></button>
    </div>
   
</div>


<div style={{display:value===2?'block':'none'}}>
      <Secondpage/>

    <div className='text-center'>
    <button onClick={()=>{setValue(value+1)}} style={{padding:'12px',width:'80%',backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:400,fontFamily:"roboto"}}>Get Started<span></span><HiOutlineArrowNarrowRight className='ms-2' style={{fontSize:'20px'}}/></button>
    </div>
    </div>

    <div style={{display:value===3?'block':'none'}}>
     <Thirdpage/>
     
    <div className='text-center'>
    <button onClick={()=>{setValue(value+1)}} style={{padding:'12px',width:'80%',backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:400,fontFamily:"roboto"}}>Get Started<span></span><HiOutlineArrowNarrowRight className='ms-2' style={{fontSize:'20px'}}/></button>
    </div>

    </div>

    <div style={{display:value===4?'block':'none'}}>
     <Otp/>
     
    <div className='text-center' style={{marginTop:'100px'}}>
    <button onClick={()=>{setValue(value+1)}} style={{padding:'12px',width:'80%',backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:400,fontFamily:"roboto"}}>Get OTP<span></span><HiOutlineArrowNarrowRight className='ms-2' style={{fontSize:'20px'}}/></button>
    </div>
    </div>

    <div style={{display:value===5?'block':'none'}}>
     <Checkotp/>
     
    <div className='text-center mt-5'>
    <button onClick={()=>{setValue(value+1)}} style={{padding:'12px',width:'80%',backgroundColor:'#96DE20',border:'none',borderRadius:'2rem',fontSize:'20px',fontWeight:400,fontFamily:"roboto"}}>Confirm OTP</button>

    <div className='text-center mt-3'>
        <p className='' style={{color:'#4B4B4B',justifyContent:'center',fontSize:'20px',fontWeight:400,fontFamily:"roboto"}}>Resend OTP</p>
    </div>
    </div>
    </div>

    <div className='animate__animated animate__fadeInUp' style={{display:value===6?'block':'none'}}>
    <Verification Santa = {value}/>
   
   <div className='text-center'>
  
   </div>

   
    </div>
   
    <div style={{display:value===7?'block':'none'}}>
    <Startselling/>
    </div>

    <div style={{display:value===8?'block':'none'}}>
      <Name/>
    </div>


    {/* <div style={{display:value===9?'block':'none'}}>
    <NavLink to='/Home'>
        <Home/>
    </NavLink>
    </div> */}

    </>

  )
}

export default Firstpage;