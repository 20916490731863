import React from 'react';
import "../src/SellScrap.css"
import { FaChevronLeft } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import {NavLink, useNavigate} from 'react-router-dom'

const SellScrap = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
           <div onClick={()=>navigate(-1)}>
            <FaChevronLeft className='SellScrapIcon1 mt-2 mx-2'/>
           </div>
            <p className='SellScrapH1 mt-4 mx-2'>Sell Your Scrap</p>
            <p className='SellScrapp1 mx-2'>In Just <span style={{color:"#ffa500 "}}>3</span> Clicks&nbsp;&nbsp;<BsArrowRight/></p>
            <p className='mt-4 mx-2' style={{color:"#ffa500",fontSize:"20px",fontWeight:"600",fontStyle:"roboto"}}>Click 1</p>
            <div class="card">
              <div class="card-body">
                <h3 style={{fontStyle:"roboto"}}>Select Scrap Category</h3>
                <div style={{height:'55vh', overflowY:'scroll'}}>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Electronics</button>
                  </NavLink>
                  <NavLink to="/SellScrap2">
                  <button className='SellScrapElectronicsbtn mt-2'>Glass</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellScrap;