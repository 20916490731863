import React, {useEffect, useState} from 'react';
import '../src/Order.css';
import { FaChevronLeft } from "react-icons/fa";
import {useNavigate} from 'react-router-dom';
import OrderAll from './OrderAll';
import OrderPending from "./OrderPending";
import OrderComplete from './OrderComplete';

const Order = () => {
    const [State, setState] = useState("")
    useEffect(()=>{
        setState('OrderAll')
    },[])
    const navigate = useNavigate();
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 col-md-6 col-sm-12'>
                    <div className='d-flex' onClick={()=>navigate(-1)}>
                        <FaChevronLeft className='OrderIcon1 mt-2 mx-2'/>
                        <p className='OrderP1 mx-5'>My Orders</p>
                    </div>
                    <div className="OrderCard card" style={{width:"94%",backgroundColor:"#F3F3F3",border:"none"}}>
                        <div className="row">
                            <div className='col-4'>
                                <div className='text-center'>
                                <button onClick={()=> {setState("OrderAll")}} className='OrderBtnAll' style={{border:"none",backgroundColor:State==='OrderAll'?'#F98523':'#F3F3F3',color:State==='OrderAll'?'#FFFFFF':'#000000'}}>All</button>
                                </div>
                            </div>
                            <div className='col-4'>
                            <div className='text-center'>
                                <button onClick={()=> {setState("OrderPending")}} className='OrderBtnPending'style={{border:"none",backgroundColor:State==='OrderPending'?'#F98523':'#F3F3F3',color:State==='OrderPending'?'#FFFFFF':'#000000'}}>Pending</button>
                                </div>
                            </div>
                            <div className='col-4'>
                            <div className='text-center'>
                                <button onClick={()=> {setState("OrderComplete")}} className='OrderBtnComplete'style={{border:"none",backgroundColor:State==='OrderComplete'?'#F98523':'#F3F3F3',color:State==='OrderComplete'?'#FFFFFF':'#000000'}}>Completed</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    {State==="OrderAll" || State === "" ?<><OrderAll /></>:<></>}
                    {State==="OrderPending"?<><OrderPending /></>:<></>}
                    {State==="OrderComplete"?<><OrderComplete /></>:<></>}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Order;