import React from 'react';
import "../src/HomeData.css";
import {NavLink} from 'react-router-dom/';
import HomeDataImg from './img/HomeDataImg.png';
import Lottie from 'react-lottie-player';    
import data from './img/data.json';

const HomeData = () => {
  return (
    <>
        <div className='container'>
            <div className='row'>
                <div className='col-6 '>
                    <h3 className='HomeDataH3'>Hey Vishal !!</h3>
                    <p className='HomeDataP1'>Let’s Save Environment & Make Some  Money</p>
                </div>
                <div className='col-6'>
                    <NavLink style={{textDecoration:"none"}} to="/SellScrap">
                     <button type='onclick' className='HomeDataBtn1'>Sell Scrap</button>
                    </NavLink>
                </div>
            </div>
            <div className="card HomeDatacard1 mt-4" style={{width:'100%'}}>
                    <div className='row justify-content-around align-items-center'>
                        <div className='col-6'>
                            <h6 className="mt-2 mx-2" style={{fontSize:"16px"}}>We Buy<br/><span style={{fontSize:"30px",fontWeight:"700",padding:"0",margin:"0"}}>Over 10+</span></h6>
                            <p className="mx-2" style={{fontSize:"16px"}}>Types of Scrap</p>
                        </div>
                        <div className='col-6'>
                            <NavLink style={{textDecoration:"none"}} to='/SellScrap'>
                                <button className='HomeDataBtn2'>Sell Scrap</button>
                            </NavLink>
                        </div>
                    </div>
            </div>
            <p className='HomeDataP2 mt-4'>Latest from Us</p>
            <div className="card HomeDatacard2 mt-4" style={{width:'100%'}}>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-6'>
                            <p className='HomeDataP3'>Donate Old Clothes</p>
                            <h1 className="HomeDataH1" >& Win Upto 1,600 Rs/-</h1>
                            <button className='HomeDataBtn3'>Donate Now</button>
                        </div>
                        <div className='col-6'>
                            <div className='HomeDataIMgt'>
                                <img className='mt-4 mx-2' style={{width:"80%"}} src={HomeDataImg} alt='home' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <p className='HomeDataP4 mt-5'>Scrap Categories</p>
                <p className='HomeDataP5 mt-5'>View All</p>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body HomeDatacenter">
                            <p className='HomeDataP6'>Metal</p>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body HomeDatacenter">
                            <p className='HomeDataP6'>Metal</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-6'>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body HomeDatacenter">
                            <p className='HomeDataP6'>Metal</p>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body HomeDatacenter">
                            <p className='HomeDataP6'>Metal</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-6'>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body HomeDatacenter">
                            <p className='HomeDataP6'>Metal</p>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body HomeDatacenter">
                            <p className='HomeDataP6'>Metal</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card HomeDatacard3 mt-4" style={{width:'100%'}}>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-6'>
                            <p className="" style={{color:"white"}} >We Give <br/> <span style={{fontSize:"28px",fontWeight:"600",lineHeight:"20px"}}> Max Price</span></p>
                            <p className="" style={{color:"white",lineHeight:"1px",fontSize:"16px",fontWeight:"300"}}>For Your Scrap</p>
                        </div>
                        <div className='col-6'>
                            <button className='HomeDataBtn4'>Sell Scrap</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card HomeDatacard4 mt-4" style={{width:'100%'}}>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-6'>
                            <h1 className="HomeDataHH1" ><span style={{fontWeight:"700"}}>Trash</span><br/> <span style={{lineHeight:"50px"}}>Ko Kro</span> <br/><span style={{fontWeight:"700"}}>Cash</span></h1>
                            <div className='HomeDataBorder'>
                                <p style={{fontSize:"18px"}}>Within<br/><span style={{fontWeight:"700"}}>24 Hours</span></p>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='HomeDataLottie'>
                            <Lottie
                                loop
                                animationData={data}
                                play
                                style={{ width:"150%",height:"25vh",marginLeft:"-30%"}}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default HomeData;