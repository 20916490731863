import React from 'react';
import '../src/Footer.css';
import { RiHome6Line } from "react-icons/ri";
import { RxBorderWidth } from "react-icons/rx";
import { MdSupportAgent } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { NavLink } from 'react-router-dom';
import styles from './Bottom.module.css';

const Footer = () => {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row' style={{ position: "fixed", bottom: "0px", width: "100%", backgroundColor: "whitesmoke", padding: "0px 10px 1px 10px" }}>
                            <div className='col-3'>
                                <div className='FootercenterIcon' style={{textAlign:"center"}}>
                                <NavLink to='/Home' className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle} style={{textAlign:"center",textDecoration:"none"}} >
                                    <RiHome6Line className='mt-5' style={{ fontSize: "30px", }} />
                                    <p className='FooterP2 mt-1' >Home</p>
                                    </NavLink>
                                    
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='FootercenterIcon' style={{textAlign:"center"}}>
                                <NavLink to='/OrderPage'  className={({isActive}) => isActive ? styles.activeStyle : styles.defaultStyle} style={{textAlign:"center",textDecoration:"none"}}>
                                    <RxBorderWidth className='mt-5' style={{ fontSize: "30px",}} />
                                    <p className='FooterP2 mt-1'>Order</p>
                                    </NavLink>
                                </div>
                                
                            </div>
                            <div className='col-3'>
                                <div className='FootercenterIcon' style={{textAlign:"center"}}>
                                <NavLink to='/Support' style={{textDecoration:"none"}}>
                                    <MdSupportAgent className='mt-5' style={{ fontSize: "30px",color:"#464646" }} />
                                    <p className='FooterP1 mt-2'>Support</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='FootercenterIcon' style={{textAlign:"center"}}>
                                <NavLink to='/Profile' style={{textDecoration:"none"}}>
                                    <CgProfile className='mt-5' style={{ fontSize: "30px",color:"#464646" }} />
                                    <p className='FooterP1 mt-2'>Profile</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;