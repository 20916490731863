import React from 'react';
import '../src/UploadPhoto.css';
import {NavLink} from 'react-router-dom';
import UploadPhotoPhoto from '../src/img/UploadPhotoPhoto.svg';
import UploadPhotoIcon1 from '../src/img/UploadPhotoIcon1.png';
// import UploadPhotoIcon2 from '../src/img/UploadPhotoIcon2.png';
// import UploadPhotoIcon3 from '../src/img/UploadPhotoIcon3.png';

import { useState } from 'react';

const UploadPhoto = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setSelectedImage(imageUrl);
  };
  return (
    <>
        <div className='container'>
            <div className='row'>
            <div className="col-12">
            <div className='d-flex justify-content-center mt-5'>
              <h3>UploadPhoto</h3>
              <img src={UploadPhotoPhoto} alt=''/>
            </div>
            <p className='text-center' style={{fontSize:"16px",fontWeight:"500",lineHeight:"21.81px",color:"#9B9B9B",fontStyle:"roboto"}}>Optional</p>

            <div className='mt-5'>
              {selectedImage ? (<img src={selectedImage} alt="Selected" style={{ width:'100%',}}/>):(
                <div className="card" style={{width:"100%",backgroundColor:"#F5F5F5",border:"1px solid #D9D9D9"}}>
                      <div className="card-body">
                        <img src={UploadPhotoIcon1} alt='d' style={{display:"block",marginLeft:"auto",marginRight:"auto"}} />
                        <p className='text-center mt-2' style={{fontSize:"16px",fontWeight:"500",lineHeight:"21.81px",color:"#9B9B9B",fontStyle:"roboto"}}>Only PNG or JPG</p>
                        <p className='text-center' style={{fontSize:"12px",lineHeight:"1px",color:"#9B9B9B",fontStyle:"roboto"}}>Less Than 15 MB</p>
                      </div>
                </div>)}
              <label htmlFor="imageUpload" className="Upload mt-4">
                Upload From Device <i className="bi bi-cloud-arrow-up mt-4" style={{ fontSize: '22px',paddingLeft:'7px' }}></i>
              </label>
              <label className="Camera mt-3">
              Click From Camera <i className="bi bi-camera mt-5" style={{ fontSize: '22px',paddingLeft:'7px' }}></i>
              </label>
              <input
                id="imageUpload"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              <NavLink to='/OrderSuccessfully'>
                      <button className='UploadPhotoIcon4' style={{marginTop:"42%"}}>Continue</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadPhoto;