import React from 'react';
import "../src/Profile.css";
import { FaChevronLeft } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import {NavLink, useNavigate} from 'react-router-dom';
import ProfileIcon22 from './img/Profile-Icon22.png';
import ProfileHelp from './img/Profile-Help-Vector.png';
import ProfileArrow from './img/Support-arrow.png';
import ProfilePrivacy from './img/Profile-Privacy-Vector.png';
import ProfileRules from './img/Profile-Rules-Vector.png';
import { BiLogOutCircle } from "react-icons/bi";

const Profile = () => {
  const navigate = useNavigate();
  return (
    <>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12 col-md-6 col-sm-6'>
          <div className='d-flex justify-content-between'>
            <div onClick={()=>navigate(-1)} >
              <FaChevronLeft className='ProfileIcon1 mt-2 mx-2'/>
            </div>
            <NavLink to='/ProfileEdit'>
            <button className='ProfileBtn1 mt-2'><span style={{fontSize:"15px"}}><MdOutlineEdit/></span>&nbsp;&nbsp;Edit Profile</button>
            </NavLink>
          </div>
          <div className='text-center mt-2'>
          <img src={ProfileIcon22} alt='kk'/>
          <p className='ProfileP1 mt-3'>Sunil Malhotra</p>
          <p className='ProfileP2'>989 654 52365</p>
          </div>
          <div class="card" style={{width:"100%",backgroundColor:"#9eea00",border:"none"}}>
              <p className='ProfileP3 mt-3 mx-4'>Total Money Earned</p>
              <p className='ProfileP4 mx-3'>₹ 56,569</p>
          </div>
          <div class="card mt-5" style={{width:"100%",border:"1px solid black"}}>
            <div className='row'>
              <div className='col-lg-2 col-2'>
                <img className='mt-2 mx-3' src={ProfileHelp} style={{width:"28px"}} alt='ak'/>
              </div>
              <div className='col-lg-8 col-8'>
              <p className='ProfileP5 mt-2'>Help and Support</p>
              </div>
              <div className='col-lg-2 col-2'>
                <img className='mt-2' src={ProfileArrow} alt='as'/>
              </div>
            </div>
          </div>
          <div class="card mt-3" style={{width:"100%",border:"1px solid black"}}>
            <div className='row'>
              <div className='col-lg-2 col-2'>
                <img className='mt-2 mx-3' src={ProfilePrivacy} style={{width:"22px"}} alt='ak'/>
              </div>
              <div className='col-lg-8 col-8'>
              <p className='ProfileP5 mt-2'>Help and Support</p>
              </div>
              <div className='col-lg-2 col-2'>
                <img className='mt-2' src={ProfileArrow} alt='as'/>
              </div>
            </div>
          </div>
          <div class="card mt-3" style={{width:"100%",border:"1px solid black"}}>
            <div className='row'>
              <div className='col-lg-2 col-2'>
                <img className='mt-2 mx-3' src={ProfileRules} style={{width:"22px"}} alt='ak'/>
              </div>
              <div className='col-lg-8 col-8'>
              <p className='ProfileP5 mt-2'>Help and Support</p>
              </div>
              <div className='col-lg-2 col-2'>
                <img className='mt-2' src={ProfileArrow} alt='as'/>
              </div>
            </div>
          </div>
          <div class="card" style={{width:"100%",border:"1px solid red",marginTop:"15%"}}>
              <p className='ProfileP6 mt-2'><BiLogOutCircle/>&nbsp;&nbsp;Log Out</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Profile;