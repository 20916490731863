import React, { useState } from 'react';
import "../src/ProfileEdit.css";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import ProfileEditIcon from './img/Profile-Icon22.png';
import { MdOutlineEdit } from "react-icons/md";
import ProfileEditBoy from './img/Profile-Icon22.png';
import ProfileEditGirl from './img/Profile-Girl-Group 149.png';
import ProfileEditNoSay from './img/Profile-NoSay-Group 151.png';
// import ProfilEditRectBox from './img/ProfileEdit-Rectangle 475.png';
import ProfileEditContactUpdateSuccessfully from './img/ProfileEditContactUpdateSuccessfully.png';



const genders = [
  { name: 'Boy', avatar: ProfileEditBoy },
  { name: "Girl", avatar: ProfileEditGirl },
  { name: 'No Say', avatar: ProfileEditNoSay }
]

const ProfileEdit = () => {
  const navigate = useNavigate();
  const [selected_gender, setSelectdGender] = useState('');
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-6 col-sm-6'>
            <div className='d-flex justify-content-between'>
              <div onClick={() => navigate(-1)} >
                <BsArrowLeft className='ProfileEditIcon1 mt-2 mx-2' />
              </div>
              <button className='ProfileEditBtn1 mt-2'>Save</button>
            </div>
            <div className='text-center mt-3'>
              <img src={ProfileEditIcon} alt='kl' />
              <p className='ProfileEditP1 text-center mt-2'>Change Picture</p>
            </div>
            <div className='d-flex justify-content-between' style={{ marginTop: "18%" }}>
              <p className='ProfileEditP2 ms-2'>Name</p>
              <MdOutlineEdit className='me-3' />
            </div>
            <p className='ProfileEditP3 ms-2'>Sunil Malhotra</p>
            <div className='d-flex justify-content-between mt-5'>
              <p className='ProfileEditP4 ms-2'>Contact Number</p>
              <MdOutlineEdit data-bs-toggle="modal" data-bs-target="#staticBackdropMod" className='me-3' />
            </div>

            {/* modal NO Edit start  */}
            <div className="modal fade" id="staticBackdropMod" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p className='ProfileEditP7 text-center'>Enter OTP sent on XXX XXX 5698<br /> to change contact number</p>
                    <div className='row'>
                      <div className='col-lg-2 col-2'>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      </div>
                    </div>
                    <div className='text-center'>
                      <button data-bs-toggle="modal" data-bs-target="#staticBackdropModd" className='ProfileEditConfirmBtn mt-4'>Confirm</button>
                      <p className='ProfileEditP8 mt-3'>Resend OTP</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal No Edit end  */}

            {/* modal Confirm start  */}
            <div className="modal fade" id="staticBackdropModd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p className='ProfileEditP9 text-center'>Enter new Contact Number</p>
                    <div className="form-group mt-2 d-flex justify-content-center align-items-center">
                      <span className="border-end country-code px-1 me-5">+91</span>
                      <input type="text" className="form-control" style={{ textAlign: "center" }} id="ec-mobile-number" aria-describedby="emailHelp" placeholder="1 2 3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4 5 6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7 8 9" />
                    </div>
                    <div className='text-center' style={{ paddingBottom: "20%" }}>
                      <button data-bs-toggle="modal" data-bs-target="#staticBackdropModd1" className='ProfileEditConfirmBtn11 mt-3' >Confirm</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal Confirm end  */}

            {/* modal OTPConfirm start  */}
            <div className="modal fade" id="staticBackdropModd1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p className='ProfileEditP7 text-center'>Enter OTP sent on XXX XXX 5698<br /> to change contact number</p>
                    <div className='row'>
                      <div className='col-lg-2 col-2'>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      <input type='number' className=''  style={{width:'100%',height:"5vh",borderRadius:'5px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
                      </div>
                      <div className='col-lg-2 col-2'>
                      </div>
                    </div>
                    <div className='text-center'>
                      <button data-bs-toggle="modal" data-bs-target="#staticBackdropModdd" className='ProfileEditConfirmBtn mt-4'>Confirm</button>
                      <p className='ProfileEditP8 mt-3'>Resend OTP</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* modal OTPConfirm end  */}

            {/* modal UpdatedSuccessfully start  */}
            <div style={{ marginBottomBottom: "50%" }} className="modal fade" id="staticBackdropModdd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p className='ProfileEditP9 text-center'>123  456  789 Contact Number<br /> Updated Successfully</p>
                    <p className='text-center mt-4'>You will be redirected in <span style={{ color: "red" }}>15</span> seconds</p>
                    <img src={ProfileEditContactUpdateSuccessfully} alt='gk' />
                  </div>
                </div>
              </div>
            </div>
            {/* modal UpdatedSuccessfully end  */}

            <p className='ProfileEditP5 ms-2'>9928424434</p>
            <p className='ProfileEditP6 ms-2 mt-5'>My Gender</p>
            <div className='row'>
              {
                genders?.map((el) => <div onClick={() => setSelectdGender(el.name)} className='col-lg-4 col-4'>
                  <div className='text-center'>
                    <img style={{
                      border: selected_gender === el.name ? '1px solid teal' : "", borderRadius: "50px"
                    }} src={el.avatar} alt='kp' />
                  </div>
                  <p style={{
                    color: selected_gender === el.name ? "teal" : ""
                  }} className='text-center mt-1'>{el.name}</p>
                </div>)
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileEdit;