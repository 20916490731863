import React from 'react'
import Order from './Order';
import Footer from './Footer';

const OrderPage = () => {
  return (
    <>
        <Order/>
        <Footer/>
    </>
  );
}

export default OrderPage;