import React from 'react';
import '../src/OrderDetail.css';
// import { NavLink } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import {useNavigate} from 'react-router-dom';
import { AiOutlineMinus } from "react-icons/ai";

const OrderDetail = () => {
  const navigate = useNavigate();
  return (
    <>
        <div className='container'>
        <div className='row'>
            <div className='col-12'>
              <div className='d-flex'>
                <div onClick={()=>navigate(-1)}>
                  <FaChevronLeft className='OrderDetailIcon1 mt-2 mx-2'/>
                </div>
                <p className='OrderDetailH1 mt-2 mx-5 text-center'>Order Details</p>
                </div>

                <div className="card" style={{width:"100%",height:'70vh',marginTop:"13%"}}>
                    {/* <div style={{padding:"10px 10px 10px 10px"}}>
                      <img style={{width:"100%",height:"28vh"}} src={OrderSuccessfullyImg1} alt=''/>
                    </div> */}
                  <div className="card-body">
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <p className='OrderDetailP2'>Category</p>
                        <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600"}}>Electronics</p>
                      </div>
                      <p className='OrderDetailP3'>Pending</p>
                    </div>
                    <div className='mt-3  '>
                      <p className='OrderDetailP4'>Sub-Category</p>
                      <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600"}}>Television</p>
                    </div>
                    <div className='mt-4'>
                      <p className='OrderDetailP5'>Weight</p>
                      <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600"}}>24 Kilograms</p>
                    </div>
                    <p className='mt-5 OrderDetailP6'>Pick Up Location</p>
                    <p className='OrderDetailH2'>Plot Number 116, Lane Number 4, Rathore Nagar, Vaishali Nagar</p>
                    <p style={{fontSize:"14px"}}>PIN <span style={{fontWeight:"600"}}>302021</span></p>
                    <div className='d-flex' style={{lineHeight:"5px"}}>
                      <p style={{fontSize:"14px"}}>District Jaipur</p>
                      <p className='ms-5' style={{fontSize:"14px"}} >State Rajasthan</p>
                    </div>
                    <p className='mt-2 OrderDetailP7'>Pick Up Date</p>
                    <h4 style={{color:"#677079"}}>03 March 2023</h4>
                  </div>
                </div>
                <div className='d-flex flex-column mt-5'>
                    <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" className='OrderDetailbtn1'>Cancel Order</button>
                </div>

                    {/* offcanvas */}

                    <div style={{height:"70vh",borderRadius:"25px 25px 0px 0px"}} className="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                  <div className='text-center m-0' data-bs-dismiss="offcanvas" aria-label="Close"><AiOutlineMinus style={{fontSize:"30px"}}/>
                  </div>
                <div className="offcanvas-body small">
                  <p className='OrderDetailOffcanvasPp1'>Reason for<br/> Cancelling Order</p>

                  <div className="card" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio" name='kk'/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Longer Pick up Time</label> */}
                          <p className='OrderDetail1Label mx-5'>Longer Pick up Time</p>
                    </div>
                  </div>
                  <div className="card mt-3" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio" name='kk'/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Lower Rates</label> */}
                          <p className='OrderDetail1Label mx-5'>Lower Rates</p>
                    </div>
                  </div>
                  <div className="card mt-3" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio" name='kk'/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Did Some Mistake</label> */}
                          <p className='OrderDetail1Label mx-5'>Did Some Mistake</p>
                    </div>
                  </div>
                  <div className="card mt-3" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio" name='kk'/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Other</label> */}
                          <p className='OrderDetail1Label mx-5'>Other</p>
                    </div>
                  </div>
                          
                  
                </div>
              </div>

                    {/* offcanvas */}

          </div>
      </div>
    </div>
    </>
  )
}

export default OrderDetail