import React from 'react';
import '../src/OrderSuccessfully.css';
import { NavLink } from 'react-router-dom';
import OrderSuccessfullyImg1 from '../src/img/OrderSuccessfullyImg1.jpg';

const OrderSuccessfully = () => {
  return (
    <>
    <div className='container' style={{scrollBehavior:"none"}}>
        <div className='row'>
            <div className='col-12'>
                <p className='OrderSuccessfullyH1 mt-2 text-center'>Order Placed Successfully</p>
                <p className='OrderSuccessfullyP1 mt-1 text-center'>Order Summary</p>

                <div class="card" style={{width:"100%",height:'70vh', overflowY:'scroll'}}>
                    <div style={{padding:"10px 10px 10px 10px"}}>
                      <img style={{width:"100%",height:"28vh"}} src={OrderSuccessfullyImg1} alt=''/>
                    </div>
                  <div class="card-body">
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <p className='OrderSuccessfullyP2'>Category</p>
                        <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600",fontStyle:"roboto"}}>Electronics</p>
                      </div>
                      <p className='OrderSuccessfullyP3'>Pending</p>
                    </div>
                    <div className='mt-3  '>
                      <p className='OrderSuccessfullyP4'>Sub-Category</p>
                      <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600",fontStyle:"roboto"}}>Television</p>
                    </div>
                    <div className='mt-4'>
                      <p className='OrderSuccessfullyP5'>Weight</p>
                      <p style={{lineHeight:"10px",color: "#677079",fontSize:"25px",fontWeight:"600",fontStyle:"roboto"}}>24 Kilograms</p>
                    </div>
                    <p className='mt-5 OrderSuccessfullyP6'>Pick Up Location</p>
                    <p className='OrderSuccessfullyH2'>Plot Number 116, Lane Number 4, Rathore Nagar, Vaishali Nagar</p>
                    <p style={{fontSize:"14px",fontStyle:"roboto"}}>PIN <span style={{fontWeight:"600"}}>302021</span></p>
                    <div className='d-flex' style={{lineHeight:"5px"}}>
                      <p style={{fontSize:"14px",fontStyle:"roboto"}}>District Jaipur</p>
                      <p className='ms-5' style={{fontSize:"14px",fontStyle:"roboto"}} >State Rajasthan</p>
                    </div>
                    <p className='mt-2 OrderSuccessfullyP7'>Pick Up Date</p>
                    <h4 style={{fontStyle:"roboto"}}>03 March 2023</h4>
                  </div>
              </div>
                <div className='d-flex flex-column mt-5'>
                  <NavLink to='/HoldTight'>
                    <button className='OrderSuccessfullybtn1'>Confirm</button>
                  </NavLink>
                </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default OrderSuccessfully;