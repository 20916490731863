// import React, { useState, useEffect } from 'react';
import logo from './img/2ndlogo.png';


function Checkotp() {
  return (
  <>
      <div className='container-fluid my-5 text-center' style={{}}>
        <img src={logo} alt="logo" style={{height:'70%',width:'70%'}} />
      </div>

      <div className='mx-5'>
        <h1 style={{lineHeight:'1',fontfamily:"roboto"}}>Enter OTP</h1>
        <p style={{lineHeight:'1',fontSize:'12px',fontfamily:"roboto"}}>OTP Sent to +91 90345678430</p>
        <p style={{lineHeight:'0',fontSize:'12px',color:'#00A56A',fontfamily:"roboto"}}>Change Number</p>
      </div>
      <div className='text-center mt-5'>
        <input  style={{width:'15%',padding:'12px',borderRadius:'15px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
        <input className='ms-4'  style={{width:'15%',padding:'12px',borderRadius:'15px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
        <input className='ms-4'  style={{width:'15%',padding:'12px',borderRadius:'15px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
        <input className='ms-4'  style={{width:'15%',padding:'12px',borderRadius:'15px',border:'1px solid #CBCBCB',outline:'none',boxShadow:'none'}}/>
      </div>     
  </>
  )
}
export default Checkotp;