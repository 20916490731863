import React from 'react';
import '../src/OrderDetail1.css';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import OrderDetail1Img1 from '../src/img/OrderSuccessfullyImg1.jpg';
import { BiCloudDownload } from "react-icons/bi";
import { AiOutlineMinus } from "react-icons/ai";



const OrderDetail1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <div className='d-flex'>
              <div onClick={() => navigate(-1)}>
                <FaChevronLeft className='OrderDetail1Icon1 mt-2 mx-2' />
              </div>
              <p className='OrderDetail1P1 mt-2 mx-5 text-center'>Order Details</p>
            </div>
            <div className="mt-3 card" style={{ width: "100%" }}>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='mt-2 mx-3 OrderDetail1P2'>Total Order Value</p>
                <p className='me-3 OrderDetail1P3'>Completed</p>
              </div>
              <p className='mx-3 OrderDetail1P4'>₹ 15,000</p>
              <p className='mx-3 OrderDetail1P5'>Date Picked up : 03 Mar 2023</p>
            </div>

            <div className="card mt-3" style={{ width: "100%", height: '55vh', overflowY: 'scroll' }}>
              <div style={{ padding: "10px 10px 10px 10px" }}>
                <img style={{ width: "100%", height: "28vh" }} src={OrderDetail1Img1} alt='' />
              </div>
              <div className="card-body">
                <div>
                  <p className='OrderDetail1P6'>Category</p>
                  <p style={{ lineHeight: "10px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>Electronics</p>
                </div>
                <div className='mt-4'>
                  <p className='OrderDetail1P8'>Sub-Category</p>
                  <p style={{ lineHeight: "5px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>Television</p>
                </div>
                <div className='mt-4'>
                  <p className='OrderDetail1P9'>Actual Weight</p>
                  <p style={{ lineHeight: "10px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>24 Kilograms</p>
                </div>
                <div className='mt-4'>
                  <p>Price</p>
                  <p style={{ lineHeight: "10px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>₹ 34/Kg</p>
                </div>
                <div className='mt-4'>
                  <p>Total Price</p>
                  <p style={{ lineHeight: "10px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>₹ 816</p>
                </div>
                <div className='mt-4'>
                  <p>Category</p>
                  <p style={{ lineHeight: "10px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>Paper</p>
                </div>
                <div className='mt-4'>
                  <p>Sub-Category</p>
                  <p style={{ lineHeight: "10px", color: "#677079", fontSize: "25px", fontWeight: "600" }}>Corrugated Paper</p>
                </div>
                <p className='mt-4 OrderDetail1P10'>Pick Up Location</p>
                <p className='OrderDetail1P11'>Plot Number 116, Lane Number 4, Rathore Nagar, Vaishali Nagar</p>
                <p style={{ fontSize: "14px", color: "#9B9B9B" }}>PIN <span style={{ fontWeight: "600", color: "black" }}>302021</span></p>
                <div className='d-flex' style={{ lineHeight: "5px" }}>
                  <p style={{ fontSize: "14px", color: "#9B9B9B" }}>District <span style={{ color: "black" }}> Jaipur</span></p>
                  <p className='ms-5' style={{ fontSize: "14px", color: "#9B9B9B" }} >State <span style={{ color: "black" }}>Rajasthan</span></p>
                </div>
                <p className='mt-2 OrderDetail1P12'>Pick Up Date</p>
                <h4 style={{ color: "#616161" }}>03 March 2023</h4>
              </div>
            </div>
            <div className='mt-3'>
              <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" className='OrderDetail1Btn2'>Download Reciept<spna className='ms-2' style={{fontSize:"25px"}} ><BiCloudDownload/></spna></button>
            </div>

              <div style={{height:"70vh",borderRadius:"25px 25px 0px 0px"}} className="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                  <div className='text-center m-0' data-bs-dismiss="offcanvas" aria-label="Close"><AiOutlineMinus style={{fontSize:"30px"}}/>
                  </div>
                <div className="offcanvas-body small">
                  <p className='OrderDetailOffcanvasPp1'>Reason for<br/> Cancelling Order</p>

                  <div className="card" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio"/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Longer Pick up Time</label> */}
                          <p className='OrderDetail1Label mx-5'>Longer Pick up Time</p>
                    </div>
                  </div>
                  <div className="card mt-3" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio"/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Lower Rates</label> */}
                          <p className='OrderDetail1Label mx-5'>Lower Rates</p>
                    </div>
                  </div>
                  <div className="card mt-3" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio"/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Did Some Mistake</label> */}
                          <p className='OrderDetail1Label mx-5'>Did Some Mistake</p>
                    </div>
                  </div>
                  <div className="card mt-3" style={{width:"100%",height:"10vh"}}>
                    <div className="card-body d-flex">
                    <input className='OrderDetail1Input1' type="radio"/>
                          {/* <label className='OrderDetail1Label mx-5' for="vehicle1">Other</label> */}
                          <p className='OrderDetail1Label mx-5'>Other</p>
                    </div>
                  </div>
                          
                  
                </div>
              </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetail1;